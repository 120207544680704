import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { BaseStore, Store, Field } from '@zento-lib/stores/BaseStore';
// eslint-disable-next-line local/ímport-casing
import type { ZentoDataboxEvent } from '@zento-lib/graphql.types';
import { RecursiveRequired } from '@zento-lib/util/types';
import { getCookie } from '@zento-lib/components/AnalyticsServices/helpers';

import { saveDataboxEvent } from './operations.graphql';

type EventInput = RecursiveRequired<ZentoDataboxEvent>;
type UserData = EventInput['user_data'];
type Content = EventInput['context'];

@Store
export class AnalyticsConversionApiStore extends BaseStore {
  /**
   * Mutation response keeper
   */
  @Field
  private message: string;

  /**
   * Submit form
   */
  public async send(payload: { eventName: string; userData?: Omit<UserData, 'session_id'>; content?: Content }) {
    const input: EventInput = {
      name: payload.eventName,
      store_id: currentStoreView().storeId,
      ...(payload.userData ? { user_data: { ...payload.userData, session_id: getCookie('_ga') } } : null),
      ...(payload.content ? { context: payload.content } : null),
    };

    return this.mutate(async () => {
      try {
        const resp = await this.dataSources.graphQl.queue({
          ...saveDataboxEvent,
          params: { input },
        });

        if (resp.saveDataboxEvent) {
          this.message = resp.saveDataboxEvent.message;
        }
      } catch (e) {
        console.error('Analytics Conversion Api Store (send): ', e);
      }
    });
  }

  public get getMessage(): string | null {
    return this.message ?? null;
  }
}
